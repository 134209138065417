.circuit-background {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  animation: backgroundPulse 3s infinite;
  pointer-events: none;
}

/* Outline styles */
.outline-path {
  stroke: #0FF;
  stroke-width: 0.5;
  fill: none;
  animation: outlinePulse 3s infinite;
  filter: drop-shadow(0 0 2px #0FF);
}

/* Base circuit path */
.circuit-base {
  fill: none;
  stroke: #0FF;
  stroke-width: 1;
  stroke-opacity: 0.1;
}

/* Animated circuit flow */
.circuit-flow {
  fill: none;
  stroke: #0FF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 4;
  stroke-opacity: 0.6;
  animation: flowAnimation 2s linear infinite;
}

/* Circuit nodes */
.circuit-node {
  fill: #0FF;
  fill-opacity: 0.2;
}

.circuit-node-glow {
  fill: #0FF;
  fill-opacity: 0.4;
  animation: nodePulse 1.5s ease-in-out infinite;
}

/* Base Animations */
@keyframes outlinePulse {
  0% { stroke-opacity: 0.4; }
  50% { stroke-opacity: 0.8; }
  100% { stroke-opacity: 0.4; }
}

@keyframes flowAnimation {
  0% { stroke-dashoffset: 24; }
  100% { stroke-dashoffset: 0; }
}

@keyframes nodePulse {
  0% {
    r: 3;
    fill-opacity: 0.6;
  }
  50% {
    r: 5;
    fill-opacity: 0.2;
  }
  100% {
    r: 3;
    fill-opacity: 0.6;
  }
}

@keyframes backgroundPulse {
  0% { opacity: 0.9; }
  50% { opacity: 1; }
  100% { opacity: 0.9; }
}

/* Responsive styles for screens <= 460px */
@media screen and (max-width: 460px) {
  .outline-path {
    stroke-width: 0.4;
    filter: drop-shadow(0 0 1px #0FF);
  }

  .circuit-base {
    stroke-width: 0.8;
  }

  .circuit-flow {
    stroke-width: 1.5;
    stroke-dasharray: 3;
  }

  @keyframes flowAnimation {
    0% { stroke-dashoffset: 18; }
    100% { stroke-dashoffset: 0; }
  }

  @keyframes nodePulse {
    0% {
      r: 2;
      fill-opacity: 0.6;
    }
    50% {
      r: 3;
      fill-opacity: 0.2;
    }
    100% {
      r: 2;
      fill-opacity: 0.6;
    }
  }
}

/* Responsive styles for screens <= 360px */
@media screen and (max-width: 360px) {
  .outline-path {
    stroke-width: 0.3;
    filter: drop-shadow(0 0 0.5px #0FF);
  }

  .circuit-base {
    stroke-width: 0.5;
  }

  .circuit-flow {
    stroke-width: 1;
    stroke-dasharray: 2;
  }

  @keyframes flowAnimation {
    0% { stroke-dashoffset: 12; }
    100% { stroke-dashoffset: 0; }
  }

  @keyframes nodePulse {
    0% {
      r: 1.5;
      fill-opacity: 0.6;
    }
    50% {
      r: 2;
      fill-opacity: 0.2;
    }
    100% {
      r: 1.5;
      fill-opacity: 0.6;
    }
  }

  .circuit-node {
    fill-opacity: 0.3;
  }

  .circuit-node-glow {
    fill-opacity: 0.5;
  }
}

/* High DPI Screen Optimizations */
@media screen and (min-resolution: 2dppx) {
  .outline-path {
    filter: drop-shadow(0 0 1px #0FF);
  }
  
  .circuit-flow {
    stroke-linecap: square;
  }
}